import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {combineLatest, Observable} from 'rxjs';
import {debounceTime, map, shareReplay} from 'rxjs/operators';
import {TenantService} from '@core/store/tenant.service';
import {BaseComponent} from '@utils/base.component';
import {UserModel} from '@core/auth/models/user';
import {UserService} from '@core/store/user.service';
import {AuthService} from '@core/auth/auth.service';
import {LanguageInfo, LanguageService} from '@core/store/language.service';
import {LoadingService} from '@core/store/loading.service';
import {ApplicationService} from '@core/store/application.service';
import {OnboardingService} from '@core/onboarding/onboarding.service';
import {Router} from '@angular/router';
import {BannerService} from '@core/store/banner.service';

@Component({
  selector: 'rea-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {
  routes: ReaNavRoute[] = [];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      debounceTime(200),
      map(result => result.matches),
      shareReplay()
    );
  user: UserModel;
  tenant: string;
  tenants: any[];
  languages: any[];
  selectedLanguage: any;
  loading$: Observable<number>;
  guidance: any;
  bannerText: string;
  showBanner = false;

  currentApp: string;
  selectedTenant: SelectedTenant;
  selectedLanguageObj: LanguageInfo;

  get languageName(): string {
    const foundLanguage = this.languages.find(i => i.isoCode === this.selectedLanguage);
    return foundLanguage ? foundLanguage.name : 'none';
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private tenantStore: TenantService,
    private authService: AuthService,
    private userStore: UserService,
    private languageStore: LanguageService,
    private loadingBar: LoadingService,
    private appStore: ApplicationService,
    public router: Router,
    private onboardingService: OnboardingService,
    private bannerService: BannerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getUser();
    this.getTenantInformation();
    this.generateRoutes();
    this.getLanguages();
    this.getCurrentApplication();

    this.loading$ = this.loadingBar.get();

    this.onboardingService.get().subscribe(val => {
      this.guidance = val;
      this.cdr.detectChanges();
    });


    this.showBanner = this.bannerService.showBanner;
    this.bannerText = this.bannerService.bannerText;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  activateGuidance(): void {
    this.onboardingService.set(this.guidance);
  }

  setNewTenant(tenant: string): void {
    if (tenant && this.tenant !== tenant) {
      // Clear language when changing tenant
      this.tenant = tenant;
      this.tenantStore.set(tenant);
      this.selectedTenant = this.getSelectedTenantObject(tenant);
      this.selectedLanguage = null;
      this.languageStore.set(null);
    }
  }

  getSelectedTenantObject(tenant): SelectedTenant {
    return this.tenants.find(t => t.code === tenant);
  }

  getSelectedLanguageObject(language): LanguageInfo {
    return this.languages.find(l => l.isoCode === language);
  }

  setLanguage(language: string): void {
    if (language && this.selectedLanguage !== language) {
      this.selectedLanguage = language;
      this.languageStore.set(language);
      this.selectedLanguageObj = this.getSelectedLanguageObject(language);
    }
  }

  logout(): void {
    this.authService.logout();
  }

  private getUser(): void {
    this.userStore.get()
      .pipe(this.unsubscribeOnDestroy)
      .subscribe((user) => {
        this.user = user;
      });
  }

  private getCurrentApplication(): void {
    this.appStore.get()
      .pipe(this.unsubscribeOnDestroy)
      .subscribe((app) => {
        this.currentApp = app;
      });
  }

  private getTenantInformation(): void {
    combineLatest([
      this.tenantStore.get(),
      this.tenantStore.getTenantList()
    ])
      .pipe(
        this.unsubscribeOnDestroy
      )
      .subscribe(([currentTenant, tenants]) => {
        if (!currentTenant && tenants.length) {
          const tenant = tenants[0];
          this.tenantStore.set(tenant.code);
        } else {
          this.tenant = currentTenant;
        }
        this.tenants = tenants;
        this.selectedTenant = this.getSelectedTenantObject(this.tenant);

      });
  }

  private getLanguages(): void {
    combineLatest([
      this.languageStore.get(),
      this.languageStore.getCountryLanguage()
    ]).pipe(this.unsubscribeOnDestroy).subscribe(([currentLanguage, availableLanguages]) => {
      if (!currentLanguage && availableLanguages.length) {
        const lang = availableLanguages[0].isoCode;
        this.setLanguage(lang);
      } else {
        this.selectedLanguage = currentLanguage;
      }
      this.languages = availableLanguages;
      this.selectedLanguageObj = this.getSelectedLanguageObject(this.selectedLanguage);
    });
  }

  private generateRoutes(): void {
    this.routes = [
      {url: '/events', name: 'Events', icon: 'account_tree', permissions: ['event_read']},
      {url: '/emails', name: 'Emails', icon: 'mail_outline', permissions: ['template_read']},
      {url: '/documents', name: 'Documents', icon: 'picture_as_pdf_outline', permissions: ['template_read']},
      {url: '/files', name: 'Uploaded files', icon: 'upload_file', permissions: ['file_read']},
      {url: '/order-search', name: 'Search', icon: 'manage_search', permissions: ['email_agent']},
      {url: '/monitoring', name: 'Monitoring', icon: 'insights', permissions: ['dev_team']},
      {url: '/configuration', name: 'Configuration', icon: 'settings', permissions: ['dev_team']}
    ];
  }
}

interface ReaNavRoute {
  url: string;
  name: string;
  icon: string;
  permissions?: string[];
  permissionsOperation?: 'AND' | 'OR';
}

interface SelectedTenant {
  name: string;
  flag: string;
}
